import React from "react";

import whatsappLogo from "../../assets/images/whatsapp.svg";
import emailLogo from "../../assets/images/email.svg";

import "./Contact.scss";

const Contact = () => {
  return (
    <address className="Contact">
      <ul>
        <li className="Contact__item">
          <a href="https://wa.me/5491155747106">
            <img src={whatsappLogo} width={24} alt="whatsapp" />
            11-5574-7106
          </a>
        </li>
        <li className="Contact__item">
          <a href="mailto: monicabelenruiztorres@gmail.com">
            <img src={emailLogo} width={24} alt="email" />
            monicabelenruiztorres@gmail.com
          </a>
        </li>
      </ul>
    </address>
  );
};

export default Contact;
