import React from "react";

import "./Name.scss";

const Name = () => {
  return (
    <div className="Name">
      <h1>Dra. Monica Belén Ruiz Torres</h1>
      <p>Estudio juridico</p>
    </div>
  );
};

export default Name;
